<template>
  <Sync
  :entities="[
    'department',
    'user',
    'call',
    'activity',
  ]"
  @done="done()"
  />
</template>

<script>
import Sync from '@/components/Sync'

export default {
  name: 'CallSync',
  components: {
    Sync
  },
  methods: {
    async done () {
      await this.$api.post('call/settings/sync-done')
      this.$router.push({ name: 'call/settings' })
    }
  }
}
</script>
